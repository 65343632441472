import axios from 'axios'
import FormData from 'form-data'

export default {
  namespaced: true,
  state: {
    origin: null,
    destination: null,
    distance: null,
    duration: null,
    polyline_points: null,
    packages: null,
    delivery_type: null,
    delivery_type_id: null,
    delivery_date: null,
    pickup_date: null,
    tracking_code: null,
    box_total: null,
    lastpall_total: null,
    annat_format_total: null,
    user_type: null
  },
  getters: {
    getPackage: (state) => (type) => {
      if (type === 'box') {
        return state.box_total
      } else if (type === 'lastpall') {
        return state.lastpall_total
      } else if (type === 'annat_format') {
        return state.annat_format_total
      }
    }
  },
  mutations: {
    SET_ROUTE(state, payload) {
      state.origin = payload.origin
      state.destination = payload.destination
    },
    SET_USER_TYPE(state, payload) {
      state.user_type = payload.user_type
    },
    SET_PACKAGES(state, payload) {
      state.packages = payload
    },
    CLEAR_STATE(state) {
      state.origin = null
      state.destination = null
      state.distance = null
      state.duration = null
      state.polyline_points = null
      state.packages = null
      state.delivery_type = null
      state.delivery_type_id = null
      state.delivery_date = null
      state.pickup_date = null
      state.tracking_code = null
      state.user_type = null
      state.box_total = null
      state.lastpall_total = null
      state.annat_format_total = null
    },
    SET_DELIVERY_TYPE(state, payload) {
      state.delivery_type = payload.delivery_type
      state.delivery_type_id = payload.delivery_type_id
      state.delivery_date = payload.delivery_date
      state.pickup_date = payload.pickup_date
    },
    SET_PRICE(state, payload) {
      if (payload.calculation_result.id === 'box') {
        state.box_total = payload.data
        state.box_total.price = payload.calculation_result.price

        state.box_total.final_price = payload.calculation_result.final_price
        state.box_total.tax = payload.calculation_result.tax
        state.box_total.tax_rate = payload.calculation_result.tax_rate
        state.box_total.discount = payload.calculation_result.discount
        state.box_total.discount_rate = payload.calculation_result.discount_rate
      } else if (payload.calculation_result.id === 'lastpall') {
        state.lastpall_total = payload.data
        state.lastpall_total.lastpall_count =
          payload.calculation_result.lastpall_count
        state.lastpall_total.price = payload.calculation_result.price
        state.lastpall_total.final_price =
          payload.calculation_result.final_price
        state.lastpall_total.tax = payload.calculation_result.tax
        state.lastpall_total.tax_rate = payload.calculation_result.tax_rate
        state.lastpall_total.discount = payload.calculation_result.discount
        state.lastpall_total.discount_rate =
          payload.calculation_result.discount_rate
      } else {
        state.annat_format_total = payload.data
        state.annat_format_total.price = 0
        state.annat_format_total.discount = 0
        state.annat_format_total.tax = 0
        state.annat_format_total.tax_rate = 0
        state.annat_format_total.discount_rate = 0
        state.annat_format_total.final_price = 0
      }
    },
    SET_DISTANCE(state, payload) {
      state.distance = payload.distance
      state.duration = payload.duration
      state.polyline_points = payload.polyline_points
    },

    SET_TRACKING_CODE(state, payload) {
      state.tracking_code = payload
    }
  },
  actions: {
    clearState({ commit }) {
      commit('CLEAR_STATE')
    },
    setRoute({ commit }, payload) {
      commit('SET_ROUTE', payload)
    },
    setUserType({ commit }, payload) {
      commit('SET_USER_TYPE', payload)
    },
    setPackages({ commit }, payload) {
      commit('SET_PACKAGES', payload)
    },
    setDeliveryType({ commit }, payload) {
      commit('SET_DELIVERY_TYPE', payload)
    },
    submitBooking({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        data.append('from', payload.from)
        data.append('to', payload.to)
        data.append('delivery_type', payload.delivery_type)
        data.append('delivery_date', payload.delivery_date)
        data.append('pickup_date', payload.pickup_date)
        data.append('sender_name', payload.sender_name)
        data.append('sender_phone', payload.sender_phone)
        data.append('sender_mail', payload.sender_mail)
        data.append('customer_name', payload.customer_name)
        data.append('customer_phone', payload.customer_phone)
        data.append('customer_mail', payload.customer_mail)
        data.append('company_name', payload.company_name)
        data.append('tax_department', payload.tax_department)
        data.append('tax_number', payload.tax_number)
        data.append('organization_number', payload.organization_number)
        data.append('address', payload.address)
        data.append('user_type', payload.user_type)
        data.append('km', payload.km)
        data.append('duration', payload.duration)
        data.append('from_lat', payload.from_lat)
        data.append('from_lng', payload.from_lng)
        data.append('to_lat', payload.to_lat)
        data.append('to_lng', payload.to_lng)
        data.append('to_name', payload.to_name)
        data.append('to_address', payload.to_address)
        data.append('from_name', payload.from_name)
        data.append('from_address', payload.from_address)
        data.append('list', JSON.stringify(payload.list))

        axios
          .post('api/bookings', data)
          .then(function (response) {
            commit('SET_TRACKING_CODE', response.data.track_code)
            resolve(response.data.track_code)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    },
    calculatePrice({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        data.append('postal_code', payload.postal_code)
        data.append('user_type', payload.user_type)
        data.append('km', payload.km)
        data.append('delivery_type', payload.delivery_type)
        data.append('list', JSON.stringify(payload.list))
        const config = {
          method: 'post',
          url: 'api/pricecalculate',
          data: data
        }
        axios(config)
          .then(function (response) {
            response.data.forEach((item) => {
              if (item) {
                commit('SET_PRICE', {
                  calculation_result: item,
                  data: payload.list.find((q) => q.id === item.id)
                })
              }
            })
            resolve(response)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    },
    setDistance({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const origin = payload.origin.lat + ',' + payload.origin.lng
        const destination =
          payload.destination.lat + ',' + payload.destination.lng
        axios
          .get(`api/direction?origin=${origin}&destination=${destination}`)
          .then((response) => {
            const payload = {
              distance: response.data.distance,
              duration: response.data.duration,
              polyline_points: response.data.polyline
            }
            commit('SET_DISTANCE', payload)
            resolve(response)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  }
}
