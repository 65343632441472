<template>
  <table class="table" cellpadding="0" cellspacing="0">
    <thead>
      <tr>
        <th>{{ $t('package_type') }}</th>
        <th>{{ $t('dimensions') }}</th>
        <th>{{ $t('weight') }}</th>
        <th>{{ $t('volume') }}</th>
        <th>{{ $t('remove') }}</th>
      </tr>
    </thead>
    <tbody v-if="rows.length === 0">
      <tr>
        <td colspan="7">
          <TheLabel>{{ $t('enter_values_for_calculate_price') }}</TheLabel>
        </td>
      </tr>
    </tbody>
    <tbody v-if="rows.length > 0">
      <tr v-for="row in rows" :key="row.id">
        <td>
          <TheLabel>{{ row.type }}</TheLabel>
        </td>
        <td>
          <TheLabel
            >{{ row.width }}cm x {{ row.size }}cm x {{ row.height }}cm</TheLabel
          >
        </td>

        <td>
          <TheLabel>{{ row.weight }} kg</TheLabel>
        </td>
        <td>
          <TheLabel>{{ row.cubic_meters }}</TheLabel>
        </td>
        <td>
          <button class="remove" @click="$emit('onRemoving', row)">
            &times;
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import TheLabel from './TheLabel'
export default {
  name: 'TheTable',
  components: {
    TheLabel
  },
  props: {
    rows: {
      type: Array
    }
  }
}
</script>

<style scoped>
.table {
  width: 100%;

  td,
  th {
    border-bottom: 1px solid rgb(var(--border-color));
  }

  thead {
    tr {
      background-color: rgb(var(--primary-color));
      color: white;
      font-size: 1rem;
      th {
        text-align: left;
        padding: 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px;
        text-align: left;
      }
    }

    tr:hover {
      td {
        background-color: #e6edf3;
      }
    }
  }
}

.remove {
  background-color: rgb(var(--secondary-color));
  width: 20px;
  height: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
</style>
