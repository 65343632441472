<template>
  <div class="booking-submit">
    <div>
      <div class="route">
        <div>
          <TheLabel> {{ $t('from') }}</TheLabel>
          <p>{{ this.origin.name }}</p>
        </div>
        <div>
          <TheLabel> {{ $t('to') }}</TheLabel>
          <p>{{ this.destination.name }}</p>
        </div>

        <div>
          <div>
            <TheLabel> {{ delivery_type }}</TheLabel>
            <p>{{ delivery_date }}</p>
          </div>
          <div>
            <TheLabel> {{ $t('content') }}</TheLabel>
            <div>
              <ul>
                <li v-if="box">
                  {{ box.box_count }} x {{ $t('box') }}:
                  {{ box.cubic_meters }} m<sup>3</sup> -
                  {{ box.kg.toFixed() }} kg
                </li>
                <li v-if="lastpall">
                  {{ lastpall.lastpall_count }} x {{ $t('lastpall') }}:
                  {{ lastpall.cubic_meters.toFixed(2) }} m<sup>3</sup> -
                  {{ lastpall.kg.toFixed() }} kg
                </li>
                <li v-if="annat_format">
                  {{ $t('annat_format') }}:
                  {{ annat_format.cubic_meters.toFixed(2) }} m<sup>3</sup> -
                  {{ annat_format.kg.toFixed() }} kg
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div class="summary">
            <table>
              <tfoot>
                <tr>
                  <td colspan="2">{{ $t('sub_total') }}</td>
                  <th>
                    <ThePriceText :price="sub_total" />
                  </th>
                </tr>
                <tr>
                  <td colspan="2">{{ $t('discount') }}</td>
                  <th>
                    <ThePriceText :price="discount" />
                  </th>
                </tr>
                <tr v-if="user_type === 'corporate'">
                  <td colspan="2">{{ $t('tax') }} (25%)</td>
                  <th>
                    <ThePriceText :price="tax" />
                  </th>
                </tr>
                <tr>
                  <td colspan="2">{{ $t('total') }}</td>
                  <th>
                    <ThePriceText :price="total" />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <form @submit.prevent="submit" class="submit-form">
        <div>
          <TheLabel
            >{{ $t('sender_name') }}
            <span class="required-field" v-if="!$v.sender_name.required"
              >*</span
            ></TheLabel
          >
          <TheTextbox tabindex="1" v-model.trim="$v.sender_name.$model" />
        </div>
        <div>
          <TheLabel
            >{{ $t('receiver_name') }}
            <span class="required-field" v-if="!$v.customer_name.required"
              >*</span
            ></TheLabel
          >
          <TheTextbox tabindex="2" v-model.trim="$v.customer_name.$model" />
        </div>

        <div>
          <TheLabel
            >{{ $t('sender_phone') }}
            <span class="required-field" v-if="!$v.sender_phone.required"
              >*</span
            ></TheLabel
          >
          <TheTextbox tabindex="3" v-model.trim="$v.sender_phone.$model" />
        </div>
        <div>
          <TheLabel
            >{{ $t('receiver_phone') }}
            <span class="required-field" v-if="!$v.customer_phone.required"
              >*</span
            ></TheLabel
          >
          <TheTextbox tabindex="4" v-model.trim="$v.customer_phone.$model" />
        </div>

        <div>
          <TheLabel
            >{{ $t('sender_email') }}
            <span class="required-field" v-if="!$v.sender_email.required"
              >*</span
            ></TheLabel
          >

          <TheTextbox
            tabindex="5"
            v-model.trim="$v.sender_email.$model"
          ></TheTextbox>
        </div>
        <div>
          <TheLabel>{{ $t('receiver_email') }}</TheLabel>
          <TheTextbox tabindex="6" v-model="customer_email" />
        </div>

        <div style="grid-column: span 2" v-if="user_type === 'corporate'">
          <TheLabel>{{ $t('company_name') }}</TheLabel>
          <TheTextbox tabindex="7" v-model="company_name" />
        </div>

        <div v-if="user_type === 'corporate'">
          <TheLabel>{{ $t('tax_department') }}</TheLabel>
          <TheTextbox tabindex="7" v-model="tax_department" />
        </div>

        <div v-if="user_type === 'corporate'">
          <TheLabel>{{ $t('tax_number') }}</TheLabel>
          <TheTextbox tabindex="7" v-model="tax_number" />
        </div>

        <div v-if="user_type === 'corporate'">
          <TheLabel>{{ $t('organization_number') }}</TheLabel>
          <TheTextbox tabindex="7" v-model="organization_number" />
        </div>

        <div v-if="user_type === 'corporate'">
          <TheLabel>{{ $t('address') }}</TheLabel>
          <TheTextbox tabindex="7" v-model="address" />
        </div>

        <div class="required-field-description">
          {{ $t('required_fields') }}
        </div>

        <div class="form-buttons" style="grid-column: span 2">
          <div class="form-button">
            <TheButton :fullwidth="true" :loading="loading"
              >{{ $t('submit') }}
            </TheButton>
          </div>
          <div class="form-button">
            <TheButton
              :fullwidth="true"
              @click="clearState"
              type="button"
              color="disabled"
              >{{ $t('start_over') }}
            </TheButton>
          </div>
        </div>
      </form>
    </div>
    <div class="courier-image-container">
      <img class="courier-image" :src="require('@/assets/courier.png')"  />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import TheLabel from '@/components/TheLabel'
import ThePriceText from '@/components/ThePriceText'
import TheTextbox from '@/components/TheTextbox'
import TheButton from '@/components/TheButton'
import { required } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'

export default {
  name: 'Submit',
  components: {
    TheLabel,
    TheButton,
    TheTextbox,
    ThePriceText
  },
  data() {
    return {
      origin: null,
      destination: null,
      distance: null,
      duration: null,
      price: null,
      delivery_date: null,
      delivery_type: null,
      packages: null,
      sender_name: '',
      customer_name: '',
      sender_phone: '',
      customer_phone: '',
      sender_email: '',
      customer_email: '',

      company_name: '',
      tax_department: '',
      tax_number: '',
      organization_number: '',
      address: '',

      loading: false,
      box: null,
      lastpall: null,
      annat_format: null,
      user_type: null
    }
  },
  validations: {
    sender_name: {
      required
    },
    customer_name: {
      required
    },
    sender_phone: {
      required
    },
    customer_phone: {
      required
    },
    sender_email: {
      required
    }
  },
  computed: {
    ...mapGetters({
      getPackage: 'booking/getPackage',
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    }),
    sub_total: function () {
      return (
        (this.box ? this.box.price : 0) +
        (this.lastpall ? this.lastpall.price : 0)
      )
    },
    discount: function () {
      return (
        (this.box ? this.box.discount : 0) +
        (this.lastpall ? this.lastpall.discount : 0)
      )
    },
    tax: function () {
      if (this.user_type === 'individual') {
        return 0
      }
      return (
        (this.box ? this.box.tax : 0) + (this.lastpall ? this.lastpall.tax : 0)
      )
    },
    total: function () {
      return (
        (this.box ? this.box.final_price : 0) +
        (this.lastpall ? this.lastpall.final_price : 0)
      )
    }
  },
  methods: {
    ...mapActions({
      submitBookingAction: 'booking/submitBooking'
    }),

    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: this.$t('errors.an_error_occured'),
          text: this.$t('errors.fill_required_fields'),
          icon: 'error',
          confirmButtonText: this.$t('close')
        })
      } else {
        this.loading = true
        this.submitStatus = 'PENDING'
        const data = {
          sender_name: this.sender_name,
          sender_mail: this.sender_email,
          sender_phone: this.sender_phone,
          customer_name: this.customer_name,
          customer_mail: this.customer_email,
          customer_phone: this.customer_phone,
          company_name: this.company_name,
          tax_department: this.tax_department,
          tax_number: this.tax_number,
          organization_number: this.organization_number,
          address: this.address,
          user_type: this.user_type
        }

        const booking = this.$store.state.booking
        data.km = Math.ceil(booking.distance / 1000)
        data.duration = Math.ceil(booking.duration / 60)
        data.from = booking.origin.postal_code
        data.to = booking.destination.postal_code
        data.delivery_type = booking.delivery_type_id
        data.pickup_date = booking.pickup_date
        data.delivery_date = booking.delivery_date
        data.from_lat = booking.origin.lat
        data.from_lng = booking.origin.lng
        data.to_lat = booking.destination.lat
        data.to_lng = booking.destination.lng

        data.to_address = booking.destination.address
        data.to_name = booking.destination.name
        data.from_address = booking.origin.address
        data.from_name = booking.origin.name

        const list = []
        let box_total = null
        let lastpall_total = null
        let annat_format_total = null

        booking.packages.forEach((item) => {
          if (item.type === 'box') {
            if (!box_total) {
              box_total = {
                id: 'box',
                type: 'box',
                cubic_meters: 0,
                kg: 0,
                box_count: 0
              }
            }
            box_total.cubic_meters += item.cubic_meters
            box_total.kg += item.weight
            box_total.box_count += 1
          } else if (item.type === 'lastpall') {
            if (!lastpall_total) {
              lastpall_total = {
                id: 'lastpall',
                type: 'lastpall',
                cubic_meters: 0,
                kg: 0,
                lastpall_count: 0
              }
            }
            lastpall_total.cubic_meters += item.cubic_meters
            lastpall_total.kg += item.weight
          } else {
            if (!annat_format_total) {
              annat_format_total = {
                id: 'annat_format',
                type: 'annat_format',
                cubic_meters: 0,
                kg: 0
              }
            }
            annat_format_total.cubic_meters += item.cubic_meters
            annat_format_total.kg += item.weight
          }
        })
        if (box_total) {
          box_total.details = booking.packages.filter(
            (item) => item.type === 'box'
          )

          list.push(box_total)
        }
        if (lastpall_total) {
          lastpall_total.details = booking.packages.filter(
            (item) => item.type === 'lastpall'
          )
          list.push(lastpall_total)
        }
        if (annat_format_total) {
          annat_format_total.details = booking.packages.filter(
            (item) => item.type === 'annat_format'
          )
          list.push(annat_format_total)
        }
        data['list'] = list

        await this.submitBookingAction(data)
          .then((track_code) => {
            this.$store.dispatch('booking/clearState')
            this.$router.push({
              name: 'Booking.Result',
              query: { status: 'success', code: track_code }
            })
            this.submitStatus = 'OK'
            this.loading = false
          })
          .catch(() => {
            this.$store.dispatch('booking/clearState')

            this.$router.push({
              name: 'Booking.Result',
              query: { status: 'error' }
            })

            this.loading = false
          })
      }
    },

    clearState() {
      this.$store.dispatch('booking/clearState')
      this.$router.push({ name: 'Booking.Index' })
    }
  },
  mounted() {
    const booking = this.$store.state.booking

    this.origin = booking.origin
    this.destination = booking.destination
    this.distance = booking.distance
    this.duration = booking.duration
    this.price = booking.price
    this.delivery_type = booking.delivery_type
    this.delivery_date = booking.delivery_date
    this.pickup_date = booking.pickup_date
    if (booking.packages) {
      this.packages = booking.packages
    }

    this.box = this.getPackage('box')
    this.lastpall = this.getPackage('lastpall')
    this.annat_format = this.getPackage('annat_format')

    if (this.authenticated) {
      this.user_type = this.user.type

      if (this.user.type === 'corporate') {
        this.company_name = this.user.company_name
        this.tax_department = this.user.tax_department
        this.tax_number = this.user.tax_number
        this.organization_number = this.user.organization_number
        this.address = this.user.address
      }
    } else {
      this.user_type = this.$store.state.booking.user_type
    }
  }
}
</script>

<style scoped>
.booking-submit {
  @media (--md) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 30px;
  }

  .route {
    @media (--md) {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
    }

    label {
      display: block;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 30px;
    }
  }

  .submit-form {
    .required-field-description {
      grid-column: span 2;
      margin-bottom: 10px;
      color: rgb(var(--danger-color));
      font-weight: bold;
    }

    .required-field {
      color: rgb(var(--danger-color));
    }

    label {
      margin-bottom: 10px;
      display: block;
    }

    input {
      width: 100%;
      margin-bottom: 10px;
    }

    button {
      width: 100%;
      margin-bottom: 10px;
    }

    @media (--md) {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
    }

    .form-buttons {
      @media (--md) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
      }

      .form-button {
        margin-bottom: 20px;
      }
    }
  }
}

.courier-image-container {
  @media (--md) {
    display: block;
  }
  display: none;

  .courier-image {
    width: 100%;
  }
}

.summary {
  border: 1px solid rgb(var(--border-color));
  padding: 10px;
  width: 100%;
  table {
    width: 100%;
  }

  th,
  td {
    text-align: left;
    width: 25%;
    padding: 5px;
  }

  tr {
    border-top: 1px solid rgb(var(--border-color));
  }

  th {
    font-weight: bold;
  }
}
</style>
