<template>
  <div>
    <div class="index">
      <div class="origin">
        <TheLabel>{{ $t('from') }}</TheLabel>
        <GmapAutocomplete
          ref="origin"
          :placeholder="$t('enter_a_location')"
          :selectFirstOnEnter="true"
          :options="{
            bounds: bounds,
            strictBounds: true
          }"
          @place_changed="setOrigin"
        />
      </div>
      <div class="destination">
        <TheLabel>{{ $t('to') }}</TheLabel>
        <GmapAutocomplete
          ref="destination"
          :placeholder="$t('enter_a_location')"
          :selectFirstOnEnter="true"
          :options="{
            bounds: bounds,
            strictBounds: true
          }"
          @place_changed="setDestination"
        />
      </div>
      <div class="user-type" v-if="!authenticated">
        <TheLabel>{{ $t('user_type') }}</TheLabel>
        <select v-model="user_type">
          <option value="" disabled selected>
            {{ $t('select_user_type') }}
          </option>
          <option value="corporate">{{ $t('corporate') }}</option>
          <option value="individual">{{ $t('individual') }}</option>
        </select>
      </div>
    </div>
    <div class="submit">
      <TheButton :loading="loading" @click="submit">{{
        $t('get_an_offer')
      }}</TheButton>
    </div>
  </div>
</template>

<script>
import TheButton from '@/components/TheButton'
import TheLabel from '@/components/TheLabel'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'

export default {
  name: 'Booking',
  data() {
    return {
      loading: false,
      user_type: '',
      origin: {
        name: null,
        address: null,
        postal_code: null,
        lat: null,
        lng: null
      },
      destination: {
        name: null,
        address: null,
        postal_code: null,
        lat: null,
        lng: null
      },
      bounds: { north: 69.1, south: 55.3, east: 24.2, west: 11 }
    }
  },
  components: {
    TheButton,
    TheLabel
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated'
    })
  },
  methods: {
    ...mapActions({
      setRouteAction: 'booking/setRoute',
      setUserTypeAction: 'booking/setUserType',
      setDistanceAction: 'booking/setDistance'
    }),

    setOrigin(data) {
      this.origin.name = this.$refs.origin.$refs.input.value
      this.origin.address = data.formatted_address
      const postal_code = data.address_components.find((element) =>
        element.types.find((type) => type === 'postal_code')
      )
      if (postal_code !== undefined) {
        this.origin.postal_code = postal_code.long_name.replace(/\s/g, '')
      }

      this.origin.lat = data.geometry.location.lat()
      this.origin.lng = data.geometry.location.lng()
    },
    setDestination(data) {
      this.destination.name = this.$refs.destination.$refs.input.value
      this.destination.address = data.formatted_address
      const postal_code = data.address_components.find((element) =>
        element.types.find((type) => type === 'postal_code')
      )
      if (postal_code !== undefined) {
        this.destination.postal_code = postal_code.long_name.replace(/\s/g, '')
      }

      this.destination.lat = data.geometry.location.lat()
      this.destination.lng = data.geometry.location.lng()
    },
    async submit() {
      if (
        !this.origin ||
        !this.destination ||
        !this.origin.name ||
        !this.destination.name ||
        !this.origin.lat ||
        !this.origin.lng ||
        !this.destination.lat ||
        !this.destination.lng ||
        !this.origin.postal_code ||
        !this.destination.postal_code
      ) {
        Swal.fire({
          title: this.$t('errors.invalid_address'),
          text: this.$t('errors.enter_correct_address'),
          icon: 'error',
          confirmButtonText: this.$t('close')
        })

        return
      }

      if (!this.authenticated && this.user_type === '') {
        Swal.fire({
          title: this.$t('errors.select_user_type'),
          text: this.$t('errors.select_user_type_description'),
          icon: 'error',
          confirmButtonText: this.$t('close')
        })
        return
      }

      this.loading = true
      const payload = {
        origin: this.origin,
        destination: this.destination
      }

      const user_type = {
        user_type: this.user_type
      }

      this.setRouteAction(payload)
      this.setUserTypeAction(user_type)
      this.setDistanceAction(payload)
        .then((response) => {
          if (response.status === 200) {
            this.$router.push({ name: 'Booking.Calculation' })
          } else {
            this.loading = false
            Swal.fire({
              title: this.$t('errors.an_error_occured'),
              text: this.$t('errors.try_again_later'),
              icon: 'error',
              confirmButtonText: this.$t('close')
            })
          }
        })
        .catch(() => {
          this.loading = false
          Swal.fire({
            title: this.$t('errors.an_error_occured'),
            text: this.$t('errors.try_again_later'),
            icon: 'error',
            confirmButtonText: this.$t('close')
          })
        })
    }
  }
}
</script>

<style scoped>
.submit {
  margin-top: 30px;
}
.index {
  display: flex;
  flex-direction: column;
  div {
    margin-bottom: 30px;
  }
  input {
    width: 100%;
  }

  .pac-target-input {
    border: 1px solid rgb(var(--border-color));
    color: rgb(var(--text-color));
    border-radius: 4px;
    font-size: 1rem;
    padding: 15px 10px;
  }

  @media (--md) {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr;
    div {
      margin-bottom: 0;
    }
  }
}

.user-type {
  select {
    border: 1px solid rgb(var(--border-color));
    color: rgb(var(--text-color));
    border-radius: 4px;
    font-size: 1rem;
    padding: 15px 10px;
    width: 100%;
  }
}

label {
  margin-bottom: 10px;
  display: block;
}
</style>
