<template>
  <div class="navigation">
    <router-link
      class="router-link"
      :class="getActiveClass('Booking')"
      to="/booking/index"
      >{{ $t('get_a_quote') }}</router-link
    >
    <router-link
      class="router-link"
      :class="getActiveClass('Tracking')"
      to="/tracking"
      >{{ $t('cargo_tracking') }}</router-link
    >

    <template v-if="authenticated">
      <div class="login-area-container">
        <div
          @click="toggleUserMenu"
          class="router-link login-area"
          :class="getActiveClass('Login')"
        >
          <div class="toggle-button">
            <span class="toggle-button-bar"></span>
            <span class="toggle-button-bar"></span>
            <span class="toggle-button-bar"></span>
          </div>
        </div>
        <ul
          class="user-menu"
          :class="[userMenuIsActive ? 'user-menu-active' : '']"
        >
          <li class="user-menu-item">
            <router-link to="/my-profile" active-class="user-menu-item-active">
              <Person width="16" height="16" />{{ $t('my_profile') }}
            </router-link>
          </li>
          <li class="user-menu-item">
            <router-link to="/my-bookings" active-class="user-menu-item-active">
              <Calendar />{{ $t('my_bookings') }}
            </router-link>
          </li>
          <li class="user-menu-item">
            <a href="#" @click.prevent="signOut"
              ><Logout />{{ $t('sign_out') }}</a
            >
          </li>
        </ul>
      </div>
    </template>
    <template v-else>
      <router-link
        class="router-link not-logged-in"
        :class="getActiveClass('Login')"
        to="/login"
        ><span class="text">{{ $t('login') }}</span>
        <span class="person"><Person width="20" /></span>
      </router-link>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Calendar from '@/assets/icons/calendar.svg'
import Logout from '@/assets/icons/logout.svg'
import Person from '@/assets/icons/person.svg'

export default {
  name: 'TheNavigation',
  components: {
    Calendar,
    Logout,
    Person
  },
  created() {
    window.addEventListener('click', (e) => {
      const classNames = [
        'user-menu-item',
        'router-link login-area',
        'toggle-button',
        'toggle-button-bar'
      ]
      if (!classNames.some((el) => el === e.target.className)) {
        this.userMenuIsActive = false
      }
    })
  },
  data() {
    return {
      userMenuIsActive: false
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    })
  },
  methods: {
    ...mapActions({
      signOutAction: 'auth/signOut'
    }),
    signOut() {
      this.signOutAction().then(() => {
        this.$store.dispatch('booking/clearState')
        this.$router.replace({
          name: 'Booking.Index'
        })
      })
    },
    getActiveClass(key) {
      try {
        if (this.$route.name.startsWith(key)) {
          return 'router-link-active'
        }
        return ''
      } catch (error) {
        return ''
      }
    },
    toggleUserMenu() {
      this.userMenuIsActive = !this.userMenuIsActive
    }
  }
}
</script>

<style scoped>
.navigation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgb(var(--border-color));

  .router-link {
    color: rgba(var(--primary-color), 0.5);
    font-size: 20px;
    margin-right: 30px;
    padding-bottom: 16px;
  }

  .router-link-active {
    color: rgba(var(--primary-color), 1);
    font-weight: bold;
    position: relative;
  }

  .router-link-active:after {
    display: block;
    content: '';
    background: rgb(var(--secondary-color));
    height: 3px;
    position: absolute;
    left: 0;
    bottom: -2px;
    top: auto;
    right: 0px;
  }
  .not-logged-in {
    margin-left: auto;
    margin-right: 0;
    span.person {
      display: block;

      @media (--md) {
        display: none;
      }

      svg {
      }
    }

    span.text {
      display: none;
      @media (--md) {
        display: block;
      }
    }
  }

  .login-area-container {
    position: relative;
    margin-left: auto;
    margin-right: 0;

    .toggle-button {
      width: 25px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      span {
        width: 100%;
        display: block;
        background: #707070;
        height: 4px;
      }
    }

    .login-area {
      color: black;
      cursor: pointer;
      margin-right: 0;
    }

    .user-menu {
      position: absolute;
      top: 43px;
      right: 0;
      background-color: white;
      display: none;
      border: 1px solid #cccccc;
      width: 160px;
    }
    .user-menu li {
      padding: 10px 20px;
    }
    .user-menu li + li {
      border-top: 1px solid #cccccc;
    }

    .user-menu li a {
      color: #777777;
    }

    .user-menu li a svg {
      margin-right: 10px;
    }

    .user-menu li a:hover {
      color: #111111;
    }

    .user-menu-active {
      display: block;
    }
  }
}
</style>
