<template>
  <div class="app">
    <Container class="header">
      <div @click="navigateToHome" class="main-logo">
        <img class="logo" width="150" :src="getLogo()" />
      </div>
      <div
        @click="navigateToBack"
        v-if="!getNavigateToHomeButtonStatus()"
        class="navigate-home"
      >
        &larr;
      </div>
    </Container>
    <Container class="navigation">
      <Navigation />
    </Container>
    <Container class="content">
      <router-view />
    </Container>
    <Container class="footer">
      <div class="copyright">{{ this.$t('brand') }} &copy; {{ year }}</div>
      <div class="links">
        <a target="_blank" :href="$t('contact_url')">{{ $t('contact') }}</a>
        <a target="_blank" :href="$t('facebook_url')"><Facebook /></a>
        <a target="_blank" :href="$t('instagram_url')"><Instagram /></a>
        <a target="_blank" :href="$t('linkedin_url')"><Linkedin /></a>
      </div>
    </Container>
  </div>
</template>

<script>
import Facebook from '@/assets/icons/social/facebook.svg'
import Instagram from '@/assets/icons/social/instagram.svg'
import Linkedin from '@/assets/icons/social/linkedin.svg'
import Container from '@/components/TheContainer.vue'
import Navigation from '@/components/TheNavigation.vue'

export default {
  name: 'App',
  beforeCreate() {},
  metaInfo: {
    title: process.env.VUE_APP_TITLE
  },
  components: {
    Container,
    Navigation,
    Facebook,
    Linkedin,
    Instagram
  },
  data() {
    return {
      year: new Date().getFullYear(),
      theme: process.env.VUE_APP_THEME
    }
  },
  methods: {
    getLogo() {
      return require('./assets/' + process.env.VUE_APP_LOGO)
    },
    navigateToHome() {
      this.$store.dispatch('booking/clearState')
      this.$router.push({ name: 'Booking.Index' })
    },
    navigateToBack() {
      this.$router.back()
    },
    getNavigateToHomeButtonStatus() {
      const blocked_names = ['Booking.Index', 'Tracking', 'Login']
      return blocked_names.some((e) => e === this.$route.name)
    }
  }
}
</script>

<style scoped>


.header {
  margin-top: 30px;
  margin-bottom: 40px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.navigation {
  margin-bottom: 30px;
}

.navigate-home {
  cursor: pointer;
  color: rgb(var(--primary-color));
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
}

.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.content {
  flex: 1;
}
.footer {
  margin-top: 76px;
  margin-bottom: 76px;
  color: rgb(var(--primary-color));
  display: flex;
  justify-content: space-between;

  .copyright {
    display: flex;
    align-items: center;
  }
  .links {
    display: flex;
    align-items: center;

    a {
      margin-left: 20px;
      color: rgb(var(--primary-color));
      display: flex;
      align-items: center;
    }

    a:first-child {
      border-right: 2px solid rgb(var(--primary-color));
      padding-right: 20px;
    }

    svg {
      width: 18px;
    }
  }
}

.main-logo {
  cursor: pointer;
}
</style>
