import axios from 'axios'

export default {
  namespaced: true,

  state: {
    token: null,
    user: null
  },

  getters: {
    authenticated(state) {
      return !!(state.token && state.user)
    },
    user(state) {
      return state.user
    }
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USER(state, data) {
      state.user = data
    }
  },
  actions: {
    async signIn({ dispatch }, credentials) {
      let response = await axios.post('api/login', credentials)
      return dispatch('attempt', response.data.token)
    },
    async attempt({ commit, state }, token) {
      if (token) {
        commit('SET_TOKEN', token)
      }
      if (!state.token) {
        return
      }

      try {
        let response = await axios.get('api/getCustomer')
        commit('SET_USER', response.data)
      } catch (e) {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
      }
    },
    signOut({ commit }) {
      return axios.post('api/logout').then(() => {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
      })
    },
    updateUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/updateCustomer', data)
          .then((response) => {
            commit('SET_USER', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
