<template>
  <div>
    <div class="calculation">
      <div class="route">
        <TheLabel> {{ $t('from') }}</TheLabel>
        <p>{{ this.origin.name }}</p>
        <ArrowIcon />
        <TheLabel> {{ $t('to') }}</TheLabel>
        <p>{{ this.destination.name }}</p>
        <ArrowIcon />
        <div class="route-footer">
          <div>
            <TheLabel> {{ $t('distance') }}</TheLabel>
            <span>{{ this.getDistance }}</span>
          </div>
          <div>
            <TheLabel> {{ $t('estimated_time') }}</TheLabel>
            <span>{{ this.getDuration }}</span>
          </div>
        </div>
      </div>
      <div class="map">
        <GmapMap
          ref="mapRef"
          :center="getCenter()"
          :zoom="getZoom()"
          style="width: 100%; min-height: 300px"
        >
          <GmapPolyline
            :path.sync="polyline_points"
            :options="{
              strokeColor: strikeColor,
              strokeOpacity: strokeOpacity,
              strokeWeight: strokeWeight
            }"
          ></GmapPolyline>
          <GmapMarker :position="getPosition(origin)" :icon="origin.icon" />
          <GmapMarker
            :position="getPosition(destination)"
            :icon="destination.icon"
          />
        </GmapMap>
      </div>
    </div>
    <div class="line"></div>
    <div class="package-type-selection-container">
      <TheLabel>{{ $t('select_package_type') }}</TheLabel>

      <ThePackageTypeSelection @onPackageTypeSelected="onPackageTypeSelected" />
      <div class="package-type-selection-table">
        <TheTable :rows="packages" @onRemoving="onRemoving" />
      </div>
      <div class="package-type-selection-submit-button">
        <TheButton :loading="loading" @click="submit">{{
          $t('calculate')
        }}</TheButton>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import TheLabel from '@/components/TheLabel'
import TheTable from '@/components/TheTable'
import TheButton from '@/components/TheButton'
import ThePackageTypeSelection from '@/components/ThePackageTypeSelection'
import ArrowIcon from '@/assets/icons/arrow.svg'
import { gmapApi } from 'vue2-google-maps'
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'Calculation',
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    }),
    google: gmapApi,
    getDistance: function () {
      return `${Math.ceil(this.distance / 1000)} km`
    },
    getDuration: function () {
      return `${Math.ceil(this.duration / 60)} min`
    }
  },
  mounted() {
    const booking = this.$store.state.booking

    this.polyline_points = booking.polyline_points
    this.origin = booking.origin
    this.destination = booking.destination
    this.distance = booking.distance
    this.duration = booking.duration

    if (booking.packages) {
      this.packages = booking.packages
    }

    if (this.authenticated) {
      this.user_type = this.user.type
    } else {
      this.user_type = this.$store.state.booking.user_type
    }
  },
  methods: {
    ...mapActions({
      setPackagesAction: 'booking/setPackages',
      calculatePriceAction: 'booking/calculatePrice'
    }),

    onPackageTypeSelected(payload) {
      this.packages.push(payload)
    },
    onRemoving(row) {
      this.packages.splice(this.packages.indexOf(row), 1)
    },
    calculatePackageTotals() {},
    latRad(lat) {
      const sin = Math.sin((lat * Math.PI) / 180)
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
    },

    async submit() {
      if (this.packages.length === 0) {
        Swal.fire({
          title: this.$t('errors.invalid_package_information'),
          text: this.$t('errors.invalid_package_information_description'),
          icon: 'error',
          confirmButtonText: this.$t('close')
        })
        return
      }

      this.loading = true
      this.setPackagesAction(this.packages)

      const data = {}

      data['km'] = Math.ceil(this.distance / 1000)
      data['postal_code'] = this.origin.postal_code
      data['delivery_type'] = 'time_courier'

      const list = []
      let box_total = null
      let lastpall_total = null
      let annat_format_total = null

      this.packages.forEach((item) => {
        if (item.type === 'box') {
          if (!box_total) {
            box_total = {
              id: 'box',
              type: 'box',
              cubic_meters: 0,
              kg: 0,
              box_count: 0
            }
          }
          box_total.cubic_meters += item.cubic_meters
          box_total.kg += item.weight
          box_total.box_count += 1
        } else if (item.type === 'lastpall') {
          if (!lastpall_total) {
            lastpall_total = {
              id: 'lastpall',
              type: 'lastpall',
              cubic_meters: 0,
              kg: 0,
              lastpall_count: 0
            }
          }
          lastpall_total.cubic_meters += item.cubic_meters
          lastpall_total.kg += item.weight
        } else {
          if (!annat_format_total) {
            annat_format_total = {
              id: 'annat_format',
              type: 'annat_format',
              cubic_meters: 0,
              kg: 0
            }
          }
          annat_format_total.cubic_meters += item.cubic_meters
          annat_format_total.kg += item.weight
        }
      })
      if (box_total) {
        list.push(box_total)
      }
      if (lastpall_total) {
        list.push(lastpall_total)
      }
      if (annat_format_total) {
        list.push(annat_format_total)
      }
      data['list'] = list
      data['user_type'] = this.user_type

      await this.calculatePriceAction(data)
        .then((response) => {
          if (response.status === 200) {
            this.$router.push({ name: 'Booking.DateSelection' })
          } else {
            Swal.fire({
              title: this.$t('errors.an_error_occured'),
              text: this.$t('errors.calculate_price'),
              icon: 'error',
              confirmButtonText: this.$t('close')
            })
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          Swal.fire({
            title: this.$t('errors.an_error_occured'),
            text: this.$t('errors.calculate_price'),
            icon: 'error',
            confirmButtonText: this.$t('close')
          })
        })
    },
    getZoom() {
      if (!this.origin) return
      if (!this.destination) return

      let latDif = Math.abs(
        this.latRad(this.origin.lat) - this.latRad(this.destination.lat)
      )
      let lngDif = Math.abs(this.origin.lng - this.destination.lng)

      let latFrac = latDif / Math.PI
      let lngFrac = lngDif / 360

      let lngZoom = Math.log(1 / latFrac) / Math.log(2)
      let latZoom = Math.log(1 / lngFrac) / Math.log(2)

      return Math.min(lngZoom, latZoom)
    },

    getCenter() {
      if (!this.origin) return
      if (!this.destination) return

      var bounds = new google.maps.LatLngBounds()
      bounds.extend(new google.maps.LatLng(this.origin.lat, this.origin.lng))
      bounds.extend(
        new google.maps.LatLng(this.destination.lat, this.destination.lng)
      )

      const latlng = bounds.getCenter()
      return {
        lat: latlng.lat(),
        lng: latlng.lng()
      }
    },
    getPosition: function (marker) {
      return {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng)
      }
    }
  },
  data() {
    return {
      loading: false,
      strikeColor: '#0fa1f7',
      strokeOpacity: 0.7,
      strokeWeight: 8,
      origin: null,
      destination: null,
      polyline_points: null,
      distance: null,
      duration: null,
      packages: [],
      user_type: null
    }
  },
  components: {
    TheLabel,
    ArrowIcon,
    ThePackageTypeSelection,
    TheTable,
    TheButton
  }
}
</script>

<style scoped>
.calculation {
  div {
    margin-bottom: 30px;
  }

  .map {
  }

  .route {
    label {
      display: block;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 30px;
    }

    svg {
      margin-bottom: 25px;
    }

    .route-footer {
      display: flex;
      justify-content: flex-start;

      div:first-child {
        margin-right: 30px;
      }
    }
  }

  @media (--md) {
    display: grid;
    grid-template-columns: 1fr 450px;
    column-gap: 30px;
  }
}

.package-type-selection-submit-button {
  text-align: center;
  margin-top: 30px;
}

.package-type-selection-table {
  margin-top: 30px;
}
</style>
