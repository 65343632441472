<template>
  <table class="table" cellpadding="0" cellspacing="0">
    <thead>
      <tr>
        <th>{{ $t('package_type') }}</th>
        <th>{{ $t('weight') }}</th>
        <th>{{ $t('volume') }}</th>
        <th>{{ $t('our_offer') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <TheLabel>{{ row.type }}</TheLabel>
        </td>
        <td>
          <TheLabel>{{ row.weight }} kg</TheLabel>
        </td>
        <td>
          <TheLabel>{{ row.cubic_meters.toFixed(2) }}</TheLabel>
        </td>
        <td>
          <div class="css-loader-container" v-if="loading">
            <div class="css-loader"></div>
          </div>
          <TheLabel v-else>
            <ThePriceText :price="row.price_offer" />
          </TheLabel>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import TheLabel from './TheLabel'
import ThePriceText from './ThePriceText'
export default {
  name: 'ThePackageTypeTable',
  components: {
    TheLabel,
    ThePriceText
  },
  props: {
    row: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.table {
  width: 100%;

  td,
  th {
    border-bottom: 1px solid rgb(var(--border-color));
  }

  thead {
    tr {
      background-color: rgb(var(--primary-color));
      color: white;
      font-size: 1rem;
      th {
        text-align: left;
        padding: 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px;
        text-align: left;
      }
    }

    tr:hover {
      td {
        background-color: #e6edf3;
      }
    }
  }
}

.css-loader-container {
  position: relative;
  width: 100%;
}
.css-loader {
  top: 50%;
  left: 30px;
  position: absolute;
  transform: translate(-50%, -50%);
}
.css-loader:after {
  display: block;
  content: ' ';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid rgb(var(--primary-color));
  border-color: rgb(var(--primary-color)) transparent rgb(var(--primary-color))
    transparent;
  animation: css-loader-action 1.2s linear infinite;
}
@keyframes css-loader-action {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
