<template>
  <div class="login-form-container">
    <form @submit.prevent="submit" class="login-form">
      <div class="form-item">
        <TheLabel>{{ $t('email') }}</TheLabel>
        <TheTextbox
          type="email"
          v-model="form.email"
          :placeholder="$t('email')"
        />
      </div>
      <div class="form-item">
        <TheLabel>{{ $t('password') }}</TheLabel>
        <TheTextbox
          type="password"
          v-model="form.password"
          :placeholder="$t('password')"
        />
      </div>
      <div class="destination">
        <TheButton :loading="loading">{{ $t('login') }}</TheButton>
      </div>
    </form>
  </div>
</template>

<script>
import TheButton from '@/components/TheButton'
import TheTextbox from '@/components/TheTextbox'
import TheLabel from '@/components/TheLabel'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'Login',
  components: {
    TheButton,
    TheTextbox,
    TheLabel
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      loading: false
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),

    submit() {
      this.loading = true
      this.signIn(this.form)
        .then(() => {
          this.$router.replace({
            name: 'Booking.Index'
          })
          this.loading = false
        })
        .catch(() => {
          Swal.fire({
            title: this.$t('erros.login_failed'),
            text: this.$t('errors.login_failed_description'),
            icon: 'error',
            confirmButtonText: this.$t('close')
          })
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.login-form-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .login-form {
    min-width: 300px;
    display: flex;
    flex-direction: column;

    .form-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      label {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
