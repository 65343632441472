<template>
  <div class="date-selection-container">
    <div v-if="this.box" class="package-type-calculation-result">
      <div class="label-header-container">
        <TheLabel class="label-header"> {{ $t('box_or_packet') }}</TheLabel>
      </div>
      <ul class="date-selection-nav">
        <li
          v-for="date in dates"
          :key="date.date"
          :class="getSelectedClass(date.date)"
          class="date-selection-nav-item"
          @click="selectDate(date.date)"
        >
          <div class="day">{{ date.day }}</div>
          <div class="month">{{ monthNames[date.month] }}</div>
          <div class="year">{{ date.year }}</div>
        </li>

        <li
          @click="openDatepickerModal"
          :class="getSelectedClass(selectedDatepickerValue)"
          class="date-selection-nav-item"
        >
          <div class="day"><Calendar /></div>
          <div class="month">Custom</div>
          <div class="year">Date</div>
        </li>
      </ul>
      <div class="package-type-table">
        <ThePackageTypeTable
          :loading="table_loading"
          :row="{
            type: `${this.box.box_count} x Box`,
            weight: this.box.kg,
            cubic_meters: this.box.cubic_meters,
            price_offer: this.box.final_price
          }"
        />
      </div>

      <div class="delivery-types-container">
        <div class="delivery-types">
          <TheDeliveryType
            id="time_courier"
            :enabled="timeCourierIsActive"
            :selected="getSelectedDeliveryType('time_courier')"
            :description="$t('timed_courier_description')"
            :name="$t('timed_courier')"
            @click="selectDeliveryType('time_courier')"
          >
            <TimeCourierIcon />
          </TheDeliveryType>
          <TheDeliveryType
            id="express_courier"
            :selected="getSelectedDeliveryType('express_courier')"
            :description="$t('express_courier_description')"
            :name="$t('express_courier')"
            @click="selectDeliveryType('express_courier')"
          >
            <ExpressCourierIcon />
          </TheDeliveryType>

          <TheDeliveryType
            id="two_hour_delivery"
            :enabled="twoHourDeliveryIsActive"
            :selected="getSelectedDeliveryType('two_hour_delivery')"
            :description="$t('two_hours_delivery_description')"
            :name="$t('two_hours_delivery')"
            @click="selectDeliveryType('two_hour_delivery')"
          >
            <TwoHourDeliveryIcon />
          </TheDeliveryType>
          <TheDeliveryType
            id="three_hour_delivery"
            :enabled="threeHourDeliveryIsActive"
            :selected="getSelectedDeliveryType('three_hour_delivery')"
            :description="$t('three_hours_delivery_description')"
            :name="$t('three_hours_delivery')"
            @click="selectDeliveryType('three_hour_delivery')"
          >
            <ThreeHourDeliveryIcon />
          </TheDeliveryType>
          <TheDeliveryType
            id="six_hour_delivery"
            :enabled="sixHourDeliveryIsActive"
            :selected="getSelectedDeliveryType('six_hour_delivery')"
            :description="$t('six_hours_delivery_description')"
            :name="$t('six_hours_delivery')"
            @click="selectDeliveryType('six_hour_delivery')"
          >
            <SixHourDeliveryIcon />
          </TheDeliveryType>
        </div>
      </div>
      <div class="complete-booking">
        <div
          v-if="this.selectedDeliveryTypeId == 'time_courier'"
          class="complete-booking-dates"
        >
          <div class="pickup-and-delivery-time-container">
            <div>
              <TheLabel>{{ $t('pickup_time') }}</TheLabel>
              <div>
                <date-picker
                  v-model="pickup_time_value"
                  :minute-step="15"
                  :hour-options="availableTimes"
                  format="HH:mm"
                  value-type="format"
                  type="time"
                  :placeholder="$t('select_pickup_time')"
                  :open.sync="pickup_time_open"
                  :disabled-time="pickupDisableTime"
                  @change="handleChangePickupTime"
                ></date-picker>
              </div>
            </div>

            <div>
              <TheLabel>{{ $t('delivery_time') }}</TheLabel>
              <div>
                <date-picker
                  v-model="delivery_time_value"
                  :minute-step="15"
                  format="MM/DD/YYYY HH:mm"
                  type="datetime"
                  :open.sync="delivery_time_open"
                  :placeholder="$t('select_delivery_time')"
                  :disabled-date="datepickerModalDisabledDate"
                  @change="handleChangeDeliveryTime"
                ></date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="this.lastpall" class="package-type-calculation-result">
      <div class="label-header-container">
        <TheLabel class="label-header"> {{ $t('lastpall') }}</TheLabel>
      </div>
      <div class="package-type-table">
        <ThePackageTypeTable
          :row="{
            type: `${this.lastpall.lastpall_count} x ${this.lastpall_localization}`,
            weight: this.lastpall.kg,
            cubic_meters: this.lastpall.cubic_meters,
            price_offer:
              this.lastpall.lastpall_count > 5
                ? this.we_call_you_for_a_price_localization
                : this.lastpall.final_price
          }"
        />
      </div>
    </div>

    <div v-if="this.annat_format" class="package-type-calculation-result">
      <div class="label-header-container">
        <TheLabel class="label-header"> {{ $t('annat_format') }}</TheLabel>
      </div>
      <div class="package-type-table">
        <ThePackageTypeTable
          :row="{
            type: this.$t('annat_format'),
            weight: this.annat_format.kg,
            cubic_meters: this.annat_format.cubic_meters,
            price_offer: this.we_call_you_for_a_price_localization
          }"
        />
      </div>
    </div>

    <div class="complete-booking">
      <div class="complete-booking-button">
        <TheButton @click="completeBooking">{{
          $t('complete_booking')
        }}</TheButton>
      </div>
    </div>

    <div
      class="datepicker-modal"
      v-bind:class="{ datepicker_active: datepicker_open }"
    >
      <div class="datepicker-modal-inner">
        <date-picker
          ref="datepicker"
          v-model="datepicker_model"
          @pick="pick"
          type="date"
          :open="datepicker_open"
          :disabled-date="datepickerModalDisabledDate"
        >
          <template v-slot:header="{}">
            <div class="close-button">
              <button @click="datepickerCloseButtonClick">&times;</button>
            </div>
          </template>
        </date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import TheLabel from '@/components/TheLabel'
import TheDeliveryType from '@/components/TheDeliveryType'
import TheButton from '@/components/TheButton'
import ThePackageTypeTable from '@/components/ThePackageTypeTable'
import Calendar from '@/assets/icons/calendar.svg'
import TimeCourierIcon from '@/assets/icons/delivery-type/time-courier.svg'
import ExpressCourierIcon from '@/assets/icons/delivery-type/express-courier.svg'
import TwoHourDeliveryIcon from '@/assets/icons/delivery-type/2-hour-delivery.svg'
import ThreeHourDeliveryIcon from '@/assets/icons/delivery-type/3-hour-delivery.svg'
import SixHourDeliveryIcon from '@/assets/icons/delivery-type/6-hour-delivery.svg'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/sv'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DateSelection',
  data() {
    return {
      we_call_you_for_a_price_localization: this.$t('we_call_you_for_a_price'),
      lastpall_localization: this.$t('lastpall'),
      delivery_time_value: null,
      pickup_time_value: null,
      delivery_time_open: false,
      pickup_time_open: false,
      datepicker_model: null,
      datepicker_open: false,
      selectedDatepickerValue: null,
      user_type: null,
      box: null,
      lastpall: null,
      annat_format: null,
      selectedDate: null,
      endOfWorkTime: 18,
      selectedDeliveryTypeId: 'time_courier',
      selectedDeliveryTypeName: this.$t('timed_courier'),
      dates: [],
      monthNames: [
        this.$t('january'),
        this.$t('february'),
        this.$t('march'),
        this.$t('april'),
        this.$t('may'),
        this.$t('june'),
        this.$t('july'),
        this.$t('august'),
        this.$t('september'),
        this.$t('october'),
        this.$t('november'),
        this.$t('december')
      ],
      times: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
      price: null,
      table_loading: false
    }
  },
  mounted() {
    const today = new Date()
    const temp = new Date()

    let i
    for (i = 0; i < 3; i++) {
      temp.setDate(today.getDate() + i)
      this.dates.push({
        day: temp.getDate(),
        month: temp.getMonth(),
        year: temp.getFullYear(),
        date: this.$moment(String(temp)).format('MM/DD/YYYY')
      })
    }

    this.selectedDate = this.$moment(String(today)).format('MM/DD/YYYY')
    this.price = this.$store.state.booking.price

    this.box = this.getPackage('box')
    this.lastpall = this.getPackage('lastpall')
    this.annat_format = this.getPackage('annat_format')

    if (!this.timeCourierIsActive) {
      this.selectedDeliveryTypeId = 'express_courier'
      this.selectedDeliveryTypeName = this.$t('express_courier')
    }

    if (this.authenticated) {
      this.user_type = this.user.type
    } else {
      this.user_type = this.$store.state.booking.user_type
    }
  },
  components: {
    TheLabel,
    TheDeliveryType,
    TheButton,
    TimeCourierIcon,
    ExpressCourierIcon,
    TwoHourDeliveryIcon,
    ThreeHourDeliveryIcon,
    SixHourDeliveryIcon,
    ThePackageTypeTable,
    Calendar,
    DatePicker
  },

  computed: {
    ...mapGetters({
      getPackage: 'booking/getPackage',
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    }),
    availableTimes() {
      const today = new Date()
      if (
        this.selectedDate !== this.$moment(String(today)).format('MM/DD/YYYY')
      )
        return this.times
      return this.times.filter((time) => time > today.getHours())
    },

    timeCourierIsActive() {
      return this.availableTimes.length > 0
    },
    twoHourDeliveryIsActive() {
      const today = new Date()
      if (
        this.selectedDate !== this.$moment(String(today)).format('MM/DD/YYYY')
      )
        return true
      const diff = this.endOfWorkTime - today.getHours()
      return diff > 2
    },
    threeHourDeliveryIsActive() {
      const today = new Date()
      if (
        this.selectedDate !== this.$moment(String(today)).format('MM/DD/YYYY')
      )
        return true
      const diff = this.endOfWorkTime - today.getHours()
      return diff > 3
    },
    sixHourDeliveryIsActive() {
      const today = new Date()
      if (
        this.selectedDate !== this.$moment(String(today)).format('MM/DD/YYYY')
      )
        return true
      const diff = this.endOfWorkTime - today.getHours()
      return diff > 6
    }
  },
  methods: {
    ...mapActions({
      setDeliveryTypeAction: 'booking/setDeliveryType',
      calculatePriceAction: 'booking/calculatePrice'
    }),
    datepickerModalDisabledDate(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    pickupDisableTime(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    openDatepickerModal() {
      this.datepicker_open = !this.datepicker_open
    },
    getSelectedClass(date) {
      return this.selectedDate === date ? 'date-selection-nav-item-active' : ''
    },
    selectDate(date) {
      this.selectedDate = date
    },
    async selectDeliveryType(deliveryTypeId) {
      this.table_loading = true
      this.selectedDeliveryTypeId = deliveryTypeId

      const data = {}
      data['km'] = Math.ceil(this.$store.state.booking.distance / 1000)
      data['postal_code'] = this.$store.state.booking.origin.postal_code
      data['delivery_type'] = deliveryTypeId

      const list = []
      let box_total = null
      let lastpall_total = null
      let annat_format_total = null

      this.$store.state.booking.packages.forEach((item) => {
        if (item.type === 'box') {
          if (!box_total) {
            box_total = {
              id: 'box',
              type: 'box',
              cubic_meters: 0,
              kg: 0,
              box_count: 0
            }
          }
          box_total.cubic_meters += item.cubic_meters
          box_total.kg += item.weight
          box_total.box_count += 1
        } else if (item.type === 'lastpall') {
          if (!lastpall_total) {
            lastpall_total = {
              id: 'lastpall',
              type: 'lastpall',
              cubic_meters: 0,
              kg: 0,
              lastpall_count: 0
            }
          }
          lastpall_total.cubic_meters += item.cubic_meters
          lastpall_total.kg += item.weight
        } else {
          if (!annat_format_total) {
            annat_format_total = {
              id: 'annat_format',
              type: 'annat_format',
              cubic_meters: 0,
              kg: 0
            }
          }
          annat_format_total.cubic_meters += item.cubic_meters
          annat_format_total.kg += item.weight
        }
      })
      if (box_total) {
        list.push(box_total)
      }
      if (lastpall_total) {
        list.push(lastpall_total)
      }
      if (annat_format_total) {
        list.push(annat_format_total)
      }
      data['list'] = list
      data['user_type'] = this.user_type

      await this.calculatePriceAction(data)
      this.table_loading = false
      this.box = this.getPackage('box')
      this.lastpall = this.getPackage('lastpall')
      this.annat_format = this.getPackage('annat_format')
    },

    getSelectedDeliveryType(deliveryTypeId) {
      return this.selectedDeliveryTypeId === deliveryTypeId
    },

    completeBooking() {
      const payload = {
        delivery_type_id: this.selectedDeliveryTypeId,
        delivery_type: this.selectedDeliveryTypeName,
        pickup_date:
          this.selectedDate +
          (this.pickup_time_value ? ' ' + this.pickup_time_value : ''),
        delivery_date: this.delivery_time_value
          ? this.$moment(String(this.delivery_time_value)).format(
              'MM/DD/YYYY HH:mm'
            )
          : ''
      }
      this.setDeliveryTypeAction(payload)
      this.$router.push({ name: 'Booking.Submit' })
    },
    pick(value) {
      this.selectedDate = this.$moment(String(value)).format('MM/DD/YYYY')
      this.selectedDatepickerValue = this.$moment(String(value)).format(
        'MM/DD/YYYY'
      )
      this.datepickerCloseButtonClick()
    },
    datepickerCloseButtonClick() {
      this.$refs.datepicker.open = false
      this.datepicker_open = false
    },
    handleChangePickupTime(value, type) {
      if (type === 'minute') {
        this.pickup_time_open = false
      }
    },
    handleChangeDeliveryTime(value, type) {
      if (type === 'minute') {
        this.delivery_time_open = false
      }
    }
  }
}
</script>

<style scoped>
.date-selection-container {
  .delivery-types-container {
    .delivery-types {
      margin-top: 30px;
      width: 100%;

      @media (--md) {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }

      .delivery-type-container {
        margin-bottom: 15px;
        width: 100%;
        @media (--md) {
          margin-bottom: 0px;
        }
      }
    }
  }

  .date-selection-nav {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid rgb(var(--border-color));
    width: 100%;
    margin-bottom: 30px;

    .date-selection-nav-item {
      list-style: none;
      cursor: pointer;
      color: rgba(var(--primary-color), 0.5);
      font-size: 2rem;
      padding-bottom: 16px;
      text-align: center;
      padding-right: 20px;
      padding-left: 20px;

      .day {
      }

      .month {
        font-size: 0.9rem;
      }

      .year {
        font-size: 0.9rem;
      }
    }

    .date-selection-nav-item-active {
      color: rgba(var(--primary-color), 1);
      font-weight: bold;
      position: relative;
    }

    .date-selection-nav-item-active:after {
      display: block;
      content: '';
      background: rgb(var(--secondary-color));
      height: 3px;
      position: absolute;
      left: 0;
      bottom: -2px;
      top: auto;
      right: 0px;
    }
  }

  .complete-booking {
    label {
      margin-bottom: 10px;
      display: block;
    }

    .complete-booking-dates {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 30px;
      .pickup-and-delivery-time-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        @media (--md) {
          flex-direction: row;
        }
      }

      .pickup-and-delivery-time-container > div:first-child {
        margin-right: 0px;
        margin-bottom: 30px;
        @media (--md) {
          margin-right: 30px;
          margin-bottom: 0;
        }
      }
    }

    .complete-booking-button {
      text-align: center;
      margin-top: 30px;
    }
  }
}

.package-type-calculation-result {
  margin-bottom: 30px;
  padding-bottom: 30px;

  .package-type-table {
    margin-bottom: 30px;
  }

  .label-header-container {
    margin-bottom: 30px;

    .label-header {
      font-size: 1.4rem;
    }
  }
}

.datepicker-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  div.datepicker-modal-inner {
    position: relative;
  }
}

.datepicker_active {
  display: flex;
}
.datepicker-modal .mx-datepicker >>> .mx-input-wrapper {
  display: none;
}

.mx-datepicker-popup >>> .mx-datepicker-header {
  display: flex;
  justify-content: flex-end;
}
.close-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    width: 30px;
    height: 30px;
    font-size: 25px;
  }
}
</style>
