import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueMeta from 'vue-meta'
import axios from 'axios'
import VueMoment from 'vue-moment'
Vue.use(VueMoment)
require(`@/styles/${process.env.VUE_APP_THEME}.css`)
require('@/styles/app.css')
require('@/store/subscriber')
import i18n from './i18n'
Vue.use(VueMeta)
Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
    region: process.env.VUE_APP_REGION,
    language: process.env.VUE_APP_LANGUAGE
  }
})
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')
})
