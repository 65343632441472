<template>
  <div>
    <div class="tracking-result-container" v-if="!loader">
      <div class="tracking-result">
        <div>{{ $t('tracking_code') }}</div>
        <div>:</div>
        <div>{{ track_code }}</div>
      </div>

      <div class="tracking-result">
        <div>{{ $t('sender_name') }}</div>
        <div>:</div>
        <div>{{ sender_name }}</div>
      </div>

      <div class="tracking-result">
        <div>{{ $t('company_name') }}</div>
        <div>:</div>
        <div>{{ company_name }}</div>
      </div>

      <div class="tracking-result">
        <div>{{ $t('status') }}</div>
        <div>:</div>
        <div>{{ status }}</div>
      </div>
    </div>

    <div class="tracking-result-container" v-if="loader">
      {{ $t('please_wait') }}...
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import FormData from 'form-data'
import Swal from 'sweetalert2'
export default {
  name: 'TrackingResult',
  data() {
    return {
      track_code: null,
      sender_name: null,
      company_name: null,
      status: null,
      loader: false
    }
  },
  mounted() {
    this.loader = true
    const data = new FormData()
    data.append('track_code', this.$route.query.code)
    let $app = this

    const config = {
      method: 'post',
      url: 'api/track',
      data: data
    }
    axios(config)
      .then(function (response) {
        $app.company_name = response.data.company_name
        $app.track_code = response.data.track_code
        $app.sender_name = response.data.sender_name
        $app.status = response.data.status
        $app.loader = false
      })
      .catch(function () {
        Swal.fire({
          title: this.$t('errors.tracking_code_incorrect'),
          text: this.$t('errors.tracking_code_incorrect_description'),
          icon: 'error',
          confirmButtonText: this.$t('close')
        }).then(() => {
          $app.$router.push({ name: 'Tracking' })
        })
      })
  }
}
</script>

<style scoped>
.tracking-result-container {
  .tracking-result {
    display: grid;
    grid-template-columns: 100px 2px auto;
    column-gap: 10px;
    margin-bottom: 10px;
    div:first-child {
      font-weight: bold;
    }
  }
}
</style>
