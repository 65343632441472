<template>
  <div class="form-container">
    <div class="form-header">
      <h1>{{ $t('my_profile') }}</h1>
    </div>
    <form class="form" @submit.prevent="submit">
      <div class="form-item">
        <TheLabel>{{ $t('full_name') }}</TheLabel>
        <TheTextbox v-model="form.name" />
      </div>
      <div class="form-item">
        <TheLabel>{{ $t('email') }}</TheLabel>
        <TheTextbox v-model="form.email" />
      </div>
      <div
        class="form-item"
        :class="user_type === 'individual' ? 'span-2' : ''"
      >
        <TheLabel>{{ $t('phone') }}</TheLabel>
        <TheTextbox v-model="form.phone" />
      </div>

      <div class="form-item" v-if="user_type === 'corporate'">
        <TheLabel>{{ $t('company_name') }}</TheLabel>
        <TheTextbox v-model="form.company_name" />
      </div>
      <div class="form-item" v-if="user_type === 'corporate'">
        <TheLabel>{{ $t('tax_department') }}</TheLabel>
        <TheTextbox v-model="form.tax_department" />
      </div>
      <div class="form-item" v-if="user_type === 'corporate'">
        <TheLabel>{{ $t('tax_number') }}</TheLabel>
        <TheTextbox v-model="form.tax_number" />
      </div>
      <div class="form-item" v-if="user_type === 'corporate'">
        <TheLabel>{{ $t('organization_number') }}</TheLabel>
        <TheTextbox v-model="form.organization_number" />
      </div>
      <div class="form-item" v-if="user_type === 'corporate'">
        <TheLabel>{{ $t('address') }}</TheLabel>
        <TheTextbox v-model="form.address" />
      </div>
      <div class="form-item" style="flex-direction: row">
        <TheButton :loading="loading">{{ $t('submit') }}</TheButton>
      </div>
    </form>
  </div>
</template>

<script>
import TheButton from '@/components/TheButton'
import TheTextbox from '@/components/TheTextbox'
import TheLabel from '@/components/TheLabel'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'Profile',
  mounted() {
    const user = this.$store.state.auth.user
    if (user) {
      this.form.email = user.email
      this.form.name = user.name
      this.form.phone = user.phone
      this.form.company_name = user.company_name
      this.form.tax_department = user.tax_department
      this.form.tax_number = user.tax_number
      this.form.organization_number = user.organization_number
      this.form.address = user.address
      this.user_type = user.type
    }
  },
  data() {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        company_name: null,
        tax_department: null,
        tax_number: null,
        organization_number: null,
        address: null,
        status: '',
        discount: '',
        type: ''
      },
      loading: false,
      user_type: null
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      updateUserAction: 'auth/updateUser'
    }),
    submit: function () {
      this.loading = true
      this.updateUserAction(this.form)
        .then(() => {
          Swal.fire({
            title: this.$t('success'),
            text: this.$t('registration_successful'),
            icon: 'success',
            confirmButtonText: this.$t('close')
          })
          this.loading = false
        })
        .catch((error) => {
          let errorMessage = error.response.data.message.email
          Swal.fire({
            title: this.$t('errors.an_error_occured'),
            html: errorMessage,
            icon: 'error',
            confirmButtonText: this.$t('close')
          })
          this.loading = false
        })
    }
  },
  components: {
    TheButton,
    TheTextbox,
    TheLabel
  }
}
</script>

<style scoped>
.form-container {
  .form-header {
    h1 {
      color: rgb(var(--primary-color));
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 40px;
    }
  }
  .form {
    @media (--md) {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }

    .form-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      @media (--md) {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
      }

      label {
        margin-bottom: 10px;
      }
    }

    .span-2 {
      grid-column: span 2;
    }
  }
}
</style>
