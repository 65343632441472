<template>
  <div class="selection-container">
    <div class="package-type-container">
      <div
        @click="selectPackage('box')"
        class="package"
        v-bind:class="{ active: this.type === 'box' }"
      >
        <div class="icon">
          <Box :width="60" />
        </div>
        <div class="label">
          <TheLabel>{{ $t('box_or_packet') }}</TheLabel>
        </div>
        <div class="radio">
          <input type="radio" id="box" value="box" v-model="type" />
        </div>
      </div>
      <div
        @click="selectPackage('lastpall')"
        class="package"
        v-bind:class="{ active: this.type === 'lastpall' }"
      >
        <div class="icon">
          <Palet :width="45" />
        </div>
        <div class="label">
          <TheLabel>{{ $t('lastpall') }}</TheLabel>
        </div>
        <div class="radio">
          <input type="radio" id="lastpall" value="lastpall" v-model="type" />
        </div>
      </div>
      <div
        @click="selectPackage('annat_format')"
        class="package"
        v-bind:class="{ active: this.type === 'annat_format' }"
      >
        <div class="icon">
          <Other :width="45" />
        </div>
        <div class="label">
          <TheLabel>{{ $t('annat_format') }}</TheLabel>
        </div>
        <div class="radio">
          <input
            type="radio"
            id="annat_format"
            value="annat_format"
            v-model="type"
          />
        </div>
      </div>
    </div>
    <div class="border"></div>
    <div class="package-values-container">
      <div class="package-values">
        <div class="package-value">
          <div class="label">
            <TheLabel>{{ $t('width') }}</TheLabel>
          </div>
          <div class="textbox-container">
            <VueNumericInput
              tabindex="1"
              placeholder="0 cm"
              v-model="width"
              size="auto"
              :controls="false"
              @keyup.enter.native="submit"
            ></VueNumericInput>
          </div>
        </div>
        <div class="package-value">
          <div class="label">
            <TheLabel>{{ $t('length') }}</TheLabel>
          </div>
          <div class="textbox-container">
            <VueNumericInput
              tabindex="2"
              placeholder="0 cm"
              v-model="size"
              size="auto"
              :controls="false"
              @keyup.enter.native="submit"
            ></VueNumericInput>
          </div>
        </div>
        <div class="package-value">
          <div class="label">
            <TheLabel>{{ $t('height') }}</TheLabel>
          </div>
          <div class="textbox-container">
            <VueNumericInput
              tabindex="3"
              placeholder="0 cm"
              v-model="height"
              size="auto"
              :controls="false"
              @keyup.enter.native="submit"
            ></VueNumericInput>
          </div>
        </div>
        <div class="package-value">
          <div class="label">
            <TheLabel>{{ $t('weight') }}</TheLabel>
          </div>
          <div class="textbox-container">
            <VueNumericInput
              tabindex="4"
              placeholder="0 kg"
              v-model="weight"
              size="auto"
              :controls="false"
              @keyup.enter.native="submit"
            ></VueNumericInput>
          </div>
        </div>
      </div>
      <div class="package-values-submit">
        <TheButton @click="submit" :fullwidth="true" size="btn-sm"
          >{{ $t('add') }}
        </TheButton>
      </div>
    </div>
  </div>
</template>

<script>
import TheLabel from '@/components/TheLabel.vue'
import TheButton from '@/components/TheButton.vue'
import Palet from '@/assets/icons/package/palet.svg'
import Box from '@/assets/icons/package/box.svg'
import Other from '@/assets/icons/package/other.svg'
import VueNumericInput from './TheNumericTextbox'
import Swal from 'sweetalert2'

export default {
  name: 'PackageTypeSelection',
  data() {
    return {
      type: 'box',
      width: null,
      size: null,
      height: null,
      weight: null
    }
  },
  methods: {
    submit() {
      if (this.type && this.width && this.size && this.height && this.weight) {
        this.$emit('onPackageTypeSelected', {
          type: this.type,
          width: this.width,
          size: this.size,
          height: this.height,
          weight: this.weight,
          id: new Date().getTime(),
          cubic_meters: parseFloat(
            parseFloat(
              parseFloat(
                (this.size * this.width * this.height) / 1000000
              ).toFixed(2)
            )
          )
        })
        this.width = null
        this.height = null
        this.weight = null
        this.size = null
      } else {
        Swal.fire({
          title: this.$t('errors.invalid_information'),
          text: this.$t('errors.enter_package_dimensions'),
          icon: 'error',
          confirmButtonText: this.$t('close')
        })
      }
    },
    selectPackage(value) {
      this.type = value
    }
  },
  components: {
    VueNumericInput,
    TheLabel,
    TheButton,
    Palet,
    Box,
    Other
  }
}
</script>

<style scoped>
.selection-container {
  margin-top: 50px;

  @media (--md) {
    display: grid;
    grid-template-columns: 1.3fr 1px 1fr;
    column-gap: 45px;
  }
}

.package-type-container {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;

  .package {
    padding: 30px 15px;
    text-align: center;
    cursor: pointer;

    .label {
      margin-bottom: 10px;
      font-size: 13px;
    }

    .icon {
      margin-bottom: 10px;

      svg {
        color: rgb(var(--primary-color));
      }
    }
  }

  .active {
    label {
      color: rgb(var(--secondary-color));
    }
  }
}

.package-values-container {
  .package-values {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .package-values-submit {
    margin-top: 15px;

    button {
      width: 100%;
    }
  }

  .package-value {
    label {
      font-size: 13px;
    }

    .textbox-container {
      margin-top: 10px;
    }

    input {
      width: 100%;
    }
  }
}

.border {
  display: none;
  @media (--md) {
    display: block;
    background-color: rgb(var(--border-color));
  }
}

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 25px;
  top: -1px;
  left: -1px;
  position: relative;
  background-color: rgb(var(--background-color));
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid rgb(var(--primary-color));
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 25px;
  top: -1px;
  left: -1px;
  position: relative;
  background-color: rgb(var(--secondary-color));
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid rgb(var(--secondary-color));
}
</style>
