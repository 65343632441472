<template>
  <div class="result">
    <div class="result-success" v-if="status === 'success'">
      <h1>{{ $t('booking_result_success_header') }}</h1>
      <p>
        {{ $t('booking_result_success_title', [track_code]) }}
        <br />
        {{ $t('booking_result_success_description') }}
      </p>
      <div>
        <TheButton @click="navigateToTracking">{{
          $t('cargo_tracking')
        }}</TheButton>
      </div>
    </div>
    <div class="result-error" v-if="status === 'error'">
      <h1>{{ $t('booking_result_error_header') }}</h1>
      <p>{{ $t('booking_result_error_description') }}</p>
      <div>
        <TheButton class="error-submit-button">{{
          $t('phone_number')
        }}</TheButton>
        <TheButton color="disabled" @click="clearState">{{
          $t('try_again')
        }}</TheButton>
      </div>
    </div>
  </div>
</template>

<script>
import TheButton from '@/components/TheButton'

export default {
  name: 'Result',
  components: {
    TheButton
  },
  mounted() {
    this.track_code = this.$route.query.code
    this.status = this.$route.query.status
  },
  data() {
    return {
      track_code: null,
      status: null
    }
  },
  methods: {
    navigateToTracking() {
      this.$router.push({
        name: 'TrackingResult',
        query: {
          code: this.track_code
        }
      })
    },
    clearState() {
      this.$store.dispatch('booking/clearState')
      this.$router.push({ name: 'Booking.Index' })
    }
  }
}
</script>

<style scoped>
.result {
  h1 {
    font-weight: bold;
    font-size: 1.5rem;
  }
  p {
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .result-success {
    h1 {
      color: rgb(var(--success-color));
    }
  }

  .result-error {
    margin-top: 30px;
    h1 {
      color: rgb(var(--error-color));
    }

    .error-submit-button {
      margin-right: 20px;
    }
  }
}
</style>
