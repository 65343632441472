<template>
  <div>
    <div class="my-bookings-container" v-if="loading">
      {{ $t('please_wait') }}
    </div>
    <div class="my-bookings-container" v-if="!loading">
      <div class="my-bookings-header">
        <h1>{{ data.booking.track_code }}</h1>
      </div>
      <div class="my-booking-content">
        <div class="booking-packages-header">
          <TheLabel>{{ $t('booking_detail') }}</TheLabel>
        </div>
        <div class="booking-booking-table">
          <table class="booking">
            <tr>
              <td>{{ $t('tracking_code') }}</td>
              <th>{{ data.booking.track_code }}</th>
            </tr>
            <tr>
              <td>{{ $t('status') }}</td>
              <th>{{ data.booking.status }}</th>
            </tr>
            <tr>
              <td>{{ $t('from') }}</td>
              <th>
                {{ data.booking.from_name }}, {{ data.booking.from_address }}
              </th>
            </tr>
            <tr>
              <td>{{ $t('to') }}</td>
              <th>{{ data.booking.to_name }}, {{ data.booking.to_address }}</th>
            </tr>
            <tr>
              <td>{{ $t('create_date') }}</td>
              <th>
                {{ new Date(data.booking.created_at).toLocaleDateString() }}
              </th>
            </tr>
            <tr>
              <td>{{ $t('delivery_date') }}</td>
              <th>
                {{ data.booking.delivery_date }}
              </th>
            </tr>
            <tr>
              <td>{{ $t('sub_total') }}</td>
              <th><ThePriceText :price="sub_total" /></th>
            </tr>
            <tr>
              <td>{{ $t('discount') }}</td>
              <th><ThePriceText :price="discount" /></th>
            </tr>
            <tr>
              <td>{{ $t('tax') }}</td>
              <th><ThePriceText :price="tax" /></th>
            </tr>
            <tr>
              <td>{{ $t('total_price') }}</td>
              <th><ThePriceText :price="final_price" /></th>
            </tr>
          </table>
        </div>
        <div class="booking-packages-header">
          <TheLabel>{{ $t('packages') }}</TheLabel>
        </div>
        <div class="booking-packages-table">
          <table class="packages">
            <thead>
              <tr>
                <th>{{ $t('type') }}</th>
                <th>{{ $t('volume') }}</th>
                <th>{{ $t('weight') }}</th>
                <th>{{ $t('price') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="packet in data.packets" :key="packet.id">
                <td>{{ packet.type }}</td>
                <td>{{ packet.cubic_meters }} m<sup>3</sup></td>
                <td>{{ packet.kg }} kg</td>
                <td>
                  <ThePriceText :price="parseFloat(packet.final_price) || 0" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import TheLabel from '@/components/TheLabel'
import ThePriceText from '@/components/ThePriceText'
export default {
  name: 'MyBookingsDetail',
  components: {
    TheLabel,
    ThePriceText
  },
  mounted() {
    this.loading = true
    let $app = this
    axios
      .get(`api/getBookingsDetail/${this.$route.params.id}`)
      .then((response) => {
        this.data = response.data
        this.loading = false
      })
      .catch(function () {
        Swal.fire({
          title: this.$t('errors.booking_not_found'),
          text: this.$t('errors.booking_not_found_description'),
          icon: 'error',
          confirmButtonText: this.$t('close')
        }).then(() => {
          $app.$router.push({ name: 'MyBookings' })
        })
        this.loading = false
      })
  },
  computed: {
    final_price: function () {
      let final_price_total = 0
      if (this.data.packets) {
        this.data.packets.forEach((item) => {
          final_price_total += parseFloat(item.final_price) || 0
        })
      }
      return parseFloat(final_price_total) || 0
    },
    discount: function () {
      let discount_total = 0
      if (this.data.packets) {
        this.data.packets.forEach((item) => {
          discount_total += parseFloat(item.discount) || 0
        })
      }
      return parseFloat(discount_total) || 0
    },
    tax: function () {
      let tax_total = 0
      if (this.data.packets) {
        this.data.packets.forEach((item) => {
          tax_total += parseFloat(item.tax) || 0
        })
      }
      return parseFloat(tax_total) || 0
    },
    sub_total: function () {
      let price_total = 0
      if (this.data.packets) {
        this.data.packets.forEach((item) => {
          price_total += parseFloat(item.price) || 0
        })
      }
      return parseFloat(price_total) || 0
    }
  },
  data() {
    return {
      data: null,
      loading: false
    }
  }
}
</script>

<style scoped>
.bordered {
  border-bottom: 1px solid rgb(var(--border-color));
  padding-top: 10px;
  padding-bottom: 10px;
}
.my-bookings-container {
  .booking-packages-header {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .booking-booking-table,
  .booking-packages-table {
    background: white;
    padding: 30px;
    border: 1px solid rgb(var(--border-color));
  }

  .my-bookings-header {
    h1 {
      color: rgb(var(--primary-color));
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 40px;
    }
  }
  .my-booking-content {
    table.booking {
      @media (--md) {
        display: table;
      }
      display: block;
      width: 100%;
      -webkit-border-vertical-spacing: 10px;
      tr {
        @media (--md) {
          display: table-row;
        }
        display: flex;
        flex-direction: column;
        td {
          @media (--md) {
            width: 200px;
            display: table-cell;
          }
          display: block;
          width: 50%;
          text-align: left;
          margin-bottom: 10px;
        }

        th {
          text-align: left;
          margin-bottom: 10px;
          display: block;
          font-weight: bold;
          @media (--md) {
            display: table-cell;
          }
        }
      }
    }

    table.packages {
      width: 100%;
      -webkit-border-vertical-spacing: 10px;
      tr {
        th {
          font-weight: bold;
          text-align: left;
        }

        td {
          text-align: left;
        }
      }
    }
  }
}
</style>
