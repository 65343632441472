<template>
  <input
    :type="type"
    :tabindex="tabindex"
    :value="value"
    @input="$emit('input', $event.target.value)"
    :placeholder="this.placeholder"
    class="textbox"
  />
</template>

<script>
export default {
  name: 'TheTextbox',
  props: {
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    value: String,
    tabindex: {
      type: String
    },
    placeholder: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>
.textbox {
  border: 1px solid rgb(var(--border-color));
  color: rgb(var(--text-color));
  border-radius: 4px;
  font-size: 1rem;
  padding: 15px 10px;
}
</style>
