import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Booking from '../views/booking/Index'
import Tracking from '../views/Tracking.vue'
import Login from '../views/Login.vue'
import Profile from '../views/Profile.vue'
import MyBookings from '../views/MyBookings.vue'
import MyBookingsDetail from '../views/MyBookingsDetail.vue'
import TrackingResult from '../views/TrackingResult.vue'
import Calculation from '../views/booking/Calculation.vue'
import Form from '../views/booking/Form.vue'
import Submit from '../views/booking/Submit.vue'
import DateSelection from '../views/booking/DateSelection.vue'
import Result from '../views/booking/Result.vue'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      return next({ name: 'Booking.Index' })
    }
  },
  {
    path: '/booking',
    component: Booking,
    name: 'Booking',
    children: [
      {
        path: 'index',
        name: 'Booking.Index',
        component: Form
      },
      {
        path: 'calculation',
        name: 'Booking.Calculation',
        component: Calculation
      },
      {
        path: 'date-selection',
        name: 'Booking.DateSelection',
        component: DateSelection
      },
      {
        path: 'submit',
        name: 'Booking.Submit',
        component: Submit
      },
      {
        path: 'result',
        name: 'Booking.Result',
        component: Result
      }
    ]
  },

  {
    path: '/tracking',
    name: 'Tracking',
    component: Tracking
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }
      next()
    }
  },
  {
    path: '/my-bookings',
    name: 'MyBookings',
    component: MyBookings,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }
      next()
    }
  },
  {
    path: '/my-bookings/:id',
    name: 'MyBookingsDetail',
    component: MyBookingsDetail,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }
      next()
    }
  },
  {
    path: '/tracking-result',
    name: 'TrackingResult',
    component: TrackingResult
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const blocked_paths = [
    'Booking.Index',
    'TrackingResult',
    'Tracking',
    'Booking.Result',
    'Login',
    'MyProfile',
    'MyBookings',
    'MyBookingsDetail'
  ]
  if (!blocked_paths.some((e) => e === to.name)) {
    if (!store.state.booking.origin || !store.state.booking.destination) {
      return next({ name: 'Booking.Index' })
    }
  }
  next()
})

export default router
