<template>
  <span>{{ formattedPrice }}</span>
</template>

<script>
export default {
  name: 'PriceText',
  props: {
    price: Number
  },
  computed: {
    formattedPrice: function () {
      return this.price.toLocaleString(process.env.VUE_APP_LOCALE, {
        style: 'currency',
        currency: process.env.VUE_APP_CURRENCY
      })
    }
  }
}
</script>

<style scoped></style>
