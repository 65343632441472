<template>
  <div class="delivery-type-container">
    <div
      @click="click"
      class="delivery-type"
      :class="{
        selected: this.selected && this.enabled,
        disabled: !this.enabled
      }"
    >
      <span class="check-icon" v-if="selected && enabled">
        <CheckIcon />
      </span>

      <div class="svg-icon-container">
        <div class="svg-icon">
          <slot />
        </div>
      </div>

      <div class="description-contianer">
        <TheLabel>{{ name }}</TheLabel>
        <p class="delivery-type-description">{{ description }}</p>
      </div>
    </div>
    <div class="disabled-description" v-if="!enabled">
      {{ $t('service_not_available') }}
    </div>
  </div>
</template>
<script>
import TheLabel from './TheLabel'
import CheckIcon from '@/assets/icons/check.svg'

export default {
  name: 'TheDeliveryType',
  components: {
    TheLabel,
    CheckIcon
  },
  methods: {
    click() {
      this.$emit('click')
    }
  },
  props: {
    icon: String,
    name: String,
    id: String,
    description: String,
    enabled: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.delivery-type-container {
  position: relative;

  .delivery-type {
    border: 1px solid #707070;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    padding: 10px;

    svg {
      width: 40px;
    }

    .svg-icon-container {
      margin-right: 20px;
      min-width: 60px;
      text-align: center;
    }

    label {
      font-size: 0.9rem;
    }
    .check-icon {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgb(var(--secondary-color));
      color: white;
      width: 18px;
      height: 18px;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }

    .delivery-type-description {
      font-size: 11px;
      margin-top: 5px;
    }

    @media (--md) {
      text-align: center;
      display: block;
      padding: 15px;

      .svg-icon-container {
        margin-right: 0;
        min-width: auto;
      }

      .delivery-type-description {
        font-size: 11px;
        margin-top: 15px;
      }

      svg {
        width: 60px;
      }
    }
  }

  .selected {
    border: 3px solid rgb(var(--secondary-color));
    .svg-icon {
      svg {
        color: rgb(var(--secondary-color));
      }
    }
  }

  .disabled {
    border: 1px solid rgb(var(--disabled-color));
    opacity: 0.4;
    svg {
      color: rgb(var(--disabled-color));
    }

    label {
      color: rgb(var(--disabled-color));
    }
  }
  .disabled-description {
    position: absolute;
    color: #f51a1a;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    font-size: 11px;
    padding: 30px 20px;
    text-align: center;
  }
}
</style>
